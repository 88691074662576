<template>
  <div class="main-wrap">
    <!-- 顶部banner -->
    <!-- <div class="module-inner banner-inner" v-if="bannerList && bannerList.length">
      <div class="banber_box banner-box swiper-container" @click="onCarouselHand(1)">
        <swiper ref="mySwiperBanner" class="swiper-wrapper" :options="bannerOption">
          <swiper-slide
            class="swiper-slide"
            v-for="item in bannerList"
            :key="item.id"
          >
            <img class="banner-img" :src="item.bannerLink" alt="" />
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </div> -->

    <!-- 课程推荐 -->
    <div id="div0" class="module-inner course-inner" v-animate="'topbpttom'" v-if="AppCourseRecommendList && AppCourseRecommendList.length">
      <div class="course-right">
        <div class="public-title">
          <div class="name" v-if="false">
            <img src="@/assets/image/knowledgeMall/2023090404.png" alt="">
            <h4>课程推荐</h4>
          </div>
          <div class="more" v-if="AppCourseRecommendList.length > 1" @click="changeNext('CourseRecom')">
            <span>换一批</span>
            <img class="" src="@/assets/image/index/Vector.png" alt="">
          </div>
        </div>
        <div class="course-list" v-if="AppCourseRecommendList.length">
          <div class="course-list-inner" v-for="(item,index) in AppCourseRecommendList" :key="index" v-show="AppCourseRecommendindex == index">
            <div class="course-info" @click="toproDetail(item)">
              <!-- :src="item.listPhoto"  -->
              <img v-lazy="item.listPhoto" :key="item.listPhoto" alt="">
              <div class="info">
                <p class="name">{{item.productName}}</p>
                <p class="label">{{item.categoryName}}丨视频数量：{{item.classHour}}个</p>
                <p class="price"><span>￥</span>{{item.couponPrice?item.couponPrice.toFixed(2):'0.00'}}</p>
              </div>
            </div>
            <p class="tips">{{item.overview}}</p>
            <div class="list">
              <div v-for="(i,j) in item.detail" :key="j" class="item tips">
                <img :src="j+1 == 1 ? Num01 : j+1 == 2 ? Num02 : j+1 == 3 ? Num03 : ''" alt="">
                {{i}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="course-cover" v-if="AppCourseRecommendAreaList && AppCourseRecommendAreaList.length">
        <img :src="AppCourseRecommendAreaList[0].bannerLink" @click="onCarouselHand(2,AppCourseRecommendAreaList[0])" alt="">
      </div>
    </div>

    <!-- 精品课程 -->
    <div id="div1" class="module-inner good-inner" v-animate="'topbpttom'" v-if="shoptypeObj.children && shoptypeObj.children.length">
      <div class="public-title">
        <div class="name">
          <img src="@/assets/image/knowledgeMall/2023090405.png" alt="">
          <h4>精品课程</h4>
        </div>
        <div class="inline-flex">
          <div class="search-box" :class="{'search-box-open' : searchShow}">
            <a-input v-if="searchInputShow" ref="inputRef" placeholder="" @blur="blurHandler" v-model="productName" class="input" />
            <a-button type="primary" @mouseenter="mouseEnterHandler" icon="search" @click="$router.push('/knowledgeMall/educationalCurriculum?code=09&productName=' + (productName ? productName : ''))">搜索</a-button>
          </div>
          <div class="more" @click="$router.push('/knowledgeMall/educationalCurriculum?code=09')">
            <span>更多 ></span>
          </div>
        </div>
      </div>
      <div class="course-menu-list">
        <div class="course-menu">
          <template>
            <swiper
              class="swiper"
              :options="menuOptiont"
            >
              <swiper-slide
                v-for="(i,index) in shoptypeObj.children" :key="i.id"
                class="swiper-item"
                @click.native="courseScroll(index)"
              >
              <p class="name">{{i.name}}</p>
              <img src="@/assets/image/knowledgeMall/2023090406.png" alt="">
              </swiper-slide>
            </swiper>
          </template>
          <div class="navigation" v-if="shoptypeObj.children && shoptypeObj.children.length > 6">
            <div class="option voice-prev" slot="button-prev">
              <img class="up" src="@/assets/image/knowledgeMall/up.png" alt="">
              <img class="up-active" src="@/assets/image/knowledgeMall/up-active.png" alt="">
            </div>
            <div class="option voice-next" slot="button-next">
              <img class="down" src="@/assets/image/knowledgeMall/down.png" alt="">
              <img class="down-active" src="@/assets/image/knowledgeMall/down-active.png" alt="">
            </div>
          </div>
        </div>
        <div class="course-list">
          <div class="scorll-div">
            <div class="item scrollBox" v-for="i in shoptypeObj.children" :key="i.id">
              <div class="big-name">
                <span>{{i.name}}</span>
                <i class="line"></i>
              </div>
              <div class="product-list" >
                <div class="item-side" @click="toproDetail(pro)" v-for="pro in i.productList" :key="pro.productId">
                  <!-- :src="pro.homePage"  -->
                  <img  v-lazy="pro.homePage" :key="pro.homePage" alt="">
                  <div class="info">
                    <p class="name mallHidden">{{ pro.productName }}</p>
                    <p class="label">{{pro.categoryName}}丨视频数量：{{pro.classHour}}个</p>
                    <p class="price" v-if="pro.couponPrice"><span>￥</span>{{ pro.couponPrice.toFixed(2) }}</p>
                    <p class="price" v-else>免费</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="course-bg">
      <!-- 主编力荐 -->
      <div id="div2" class="module-inner main-push " v-animate="'topbpttom'" v-if="StoresRecommendList && StoresRecommendList.length">
        <div class="public-title">
          <div class="name">
            <img src="@/assets/image/knowledgeMall/2023090401.png" alt="">
            <h4>主编力荐</h4>
          </div>
          <div class="more" v-if="StoresRecommendList.length > 1" @click="changeNext">
            <span>换一批</span>
            <img class="" src="@/assets/image/index/Vector.png" alt="">
          </div>
        </div>
        
        <div class="product-plate" v-for="(item,index) in StoresRecommendList" :key="index" v-show="StoresRecommendIndex == index">
          <div class="product-info">
            <img class="cover" :src="item.banner" alt="">
            <p class="txt">{{item.detail}}</p>
          </div>
          <div class="product-list">
            <div class="item" @click="toproDetail(i)" v-for="i in item.productColumnRelations" :key="i.id">
              <div class="item-side">
                <!-- listPhoto -->
                <!-- :src="i.homePage"  -->
                <img class="cover" v-lazy="i.homePage" :key="i.homePage" alt="">
                <div class="info">
                  <p class="name">{{ i.productName }}</p>
                  <p class="label" v-if="i.productType == 1">{{i.categoryName}}丨视频数量：{{i.classHour}}个</p>
                  <p class="label" v-else></p>
                  <p class="price"><span>￥</span>{{i.couponPrice?i.couponPrice.toFixed(2):'0.00'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 套餐详情 -->
      <div id="div3" class="module-inner package" v-animate="'topbpttom'" v-if="PackageList && PackageList.length">
        <div class="public-title">
          <div class="name">
            <img src="@/assets/image/index/roup9646.png" alt="">
            <h4>套餐推荐</h4>
          </div>
          <div class="more" @click="$router.push('/knowledgeMall/setmeal')">
            <span>更多></span>
          </div>
        </div>
        <div class="product-package">
          <div class="item-side" v-for="(item, index) in PackageList" :key="item.packageId" v-show="index<2">
            <div class="product">
              <div class="product-side">
                <div class="item" v-for="i in item.productList" :key="i.productId">
                  <!-- :src="i.picture" -->
                  <img class="cover" v-lazy="i.picture" :key="i.picture" alt="">
                  <div class="info">
                    <p class="name">{{ i.name }}</p>
                    <span class="price">原价：{{i.originalPrice}}元</span>
                  </div>
                </div>
                <img class="connect-icon" src="@/assets/image/knowledgeMall/2023090402.png" alt="">
              </div>
            </div>
            <div class="buy-side">
              <div class="title">
                <img src="@/assets/image/knowledgeMall/2023090403.png" alt="">
                <span>套餐价格</span>
              </div>
              <div class="price"><span>￥</span>{{item.packagePrice?item.packagePrice.toFixed(2):'0.00'}}</div>
              <div class="ori-price">原价：￥{{item.originalPrice?item.originalPrice.toFixed(2):'0.00'}}</div>
              <div class="btn-foo">
                <p class="all-btn-small btn"
                  @click="
                    $router.push(
                      '/knowledgeMall/setMealDetail?packageId=' +
                        $AES.encode_data(
                          item.packageId + ''
                        )
                    )
                  ">立即购买</p>
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="book-bg" >
      <div id="div4" class="module-inner">
        <!-- 华夏图书 -->
        <div class="book-inner huaxia-book" v-animate="'topbpttom'" v-if="Books_productList && Books_productList.length">
          <img class="book-cover" src="@/assets/image/knowledgeMall/book-01.png" alt="">
          <div class="box_Back">
            <div class="bookBanner banber_box banner-box swiper">
              <swiper ref="BOOKmySwiper" class="swiper-wrapper swiper-container" :options="Books_productListOption">
                <swiper-slide
                  class="swiper-slide"
                  v-for="(item, index) in Books_productList"
                  :key="item.id"
                >
                  <div class="typeBox" :class="{cccBox:index%2 != 0}">
                    <img class="banner-img" v-if="index%2 == 0" src="@/assets/image/RevisionHome/bookIcon_1.png" alt="" />
                    <img class="banner-img" v-else src="@/assets/image/RevisionHome/bookIcon_2.png" alt="" />
                    <p class="typeText">{{ item.name }}</p>
                    <p class="btn"
                      :class="{typecolor:index%2 == 0}"
                      @click="gochildtype(item.id,item.code,'05',item.name)"
                    >
                      查看所有<a-icon class="aIcon" type="right" />
                    </p>
                  </div>
                  <div class="contentBox" :class="{cccBox:index%2 != 0}">
                    <div class="pro_box" @click="toproDetail(i)" v-for="i in item.children" :key="i.productId">
                      <!-- :src="i.pcPhoto" -->
                      <img class="banner-img" v-lazy="i.pcPhoto" :key="i.pcPhoto" :onerror="GLOBAL.errorimg()" alt="" />
                      <div class="r_mess">
                        <p class="name mallHidden">{{ i.name }}</p>
                        <div class="priceOption">
                          <p class="price">{{ i.couponPrice }}</p>
                          <img class="joinCar" @click="joinShopCar($event,i)" src="@/assets/image/RevisionHome/shopCarIcon.png" alt="">
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="Books_swiper-button-next"><a-icon type="left" /></div>
              <div class="Books_swiper-button-prev"><a-icon type="right" /></div>
            </div>
          </div>
        </div>
        <!-- 精品教具 -->
        <div class="book-inner jingpin-book" v-animate="'topbpttom'" v-if="training_productList && training_productList.length">
          <img class="book-cover" src="@/assets/image/knowledgeMall/book-02.png" alt="">
          <div class="handle">
            <div class="picture">
              <img class="banner-img" :src="training_Handle.pcPhoto" :onerror="GLOBAL.errorimg()" alt="">
              <div class="colorBox"><img src="@/assets/image/RevisionHome/training.png" alt=""></div>
            </div>
            <div class="handleMess">
              <div class="mess">
                <p class="name">{{ training_Handle.name }} <span class="price">{{ training_Handle.couponPrice }}</span></p>
                <p class="all-btn-small btn1" @click="toproDetail(training_Handle)">立即购买</p>
                <p class="btn2" @click="tolist('10', '06', '精品教具')">查看所有<a-icon class="aIcon" type="right" /></p>
              </div>
              
              <div class="trainingBanner banber_box banner-box">
                <swiper ref="trainingmySwiper" class="swiper-wrapper swiper-container" :options="training_productListOption">
                  <swiper-slide
                    class="swiper-slide"
                    v-for="(item, index) in training_productList"
                    :key="item.id"
                    @click.native="settraining_Handle(item)"
                  >
                    <div class="typeBox" :class="{cccBox:index%2 != 0}">
                      <!-- :src="item.pcPhoto" -->
                      <img class="banner-img" v-lazy="item.pcPhoto" :key="item.pcPhoto" alt="" />
                      <p class="name mallHidden" :title="item.name">{{ item.name }}</p>
                      <div class="priceOption">
                        <p class="price">{{ item.couponPrice }}</p>
                        <img class="joinCar" @click="joinShopCar($event,item)" src="@/assets/image/RevisionHome/shopCarIcon.png" alt="">
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="training_swiper-button-next"><a-icon type="left" /></div>
                <div class="training_swiper-button-prev"><a-icon type="right" /></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Sidebar ref="sidebar"/>
  </div>
</template>

<script>
import Sidebar from "@/components/shopMallSidebar.vue";
const Num01 = require('@/assets/image/knowledgeMall/num-01.png')
const Num02 = require('@/assets/image/knowledgeMall/num-02.png')
const Num03 = require('@/assets/image/knowledgeMall/num-03.png')
import joinShop from '../index/revisionHome/mixins/joinShop.vue' // 加购方法
export default {
  mixins: [joinShop],
  // 可用组件的哈希表
  components: { Sidebar },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      Num01,
      Num02,
      Num03,
      menuOptiont: { // 精品课程菜单
        direction: 'vertical', // 垂直切换
        slidesPerView: 'auto',
        spaceBetween: 11,
        centeredSlides: false,
        centeredSlidesBounds: false,
        navigation: {
          nextEl: ".voice-next",
          prevEl: ".voice-prev",
          disabledClass: "my-button-disabled",
        },
      },

      Books_productList: [], // 图书商品列表
      Books_productListOption: {
        // loop: true, // 循环模式选项
        autoplay: false,
        slidesPerView: 3,
        spaceBetween: 28,
        initialSlide: 0,
        pagination: {
          el: "",
          type: 'bullets'
        },
        navigation: {
          nextEl: '.Books_swiper-button-next',
          prevEl: '.Books_swiper-button-prev',
        },
      },

      training_productList: [], // 教具列表
      training_Handle: {}, // 当前选中教具
      training_productListOption: {
        // loop: true, // 循环模式选项
        autoplay: false,
        slidesPerView: 5,
        spaceBetween: 28,
        initialSlide: 5,
        pagination: {
          el: "",
          type: 'bullets'
        },
        navigation: {
          nextEl: '.training_swiper-button-next',
          prevEl: '.training_swiper-button-prev',
        }
      },

      // 顶部轮播
      bannerList: [],
      bannerOption: {
        slidesPerView:"auto",
        //显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //允许分页点击跳转
        },
        centeredSlides : true,
        //自动轮播
        autoplay: {
          delay: 8000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        //开启循环模式
        loop: true,
        effect:'fade',
        on: {
          slideChange: () => {
            this.getSwiperIndex();
          },
        },
      },

      // 主编力荐列表
      StoresRecommendList: [],
      StoresRecommendIndex: 0,

      // 套餐列表
      PackageList: [],

      // 课程推荐列表
      AppCourseRecommendList: [],
      AppCourseRecommendindex: 0,

      // 课程推荐广告列表
      AppCourseRecommendAreaList: [],

      // 精品课程
      shoptypeObj: {},
      scrollList: [],
      searchShow:false,
      searchInputShow:false,

      productName:'',
    }
  },
  // 事件处理器
  methods: {
    tolist(w, e, r) {
      this.$router.push(
        "/knowledgeMall/educationalCurriculum?id=" +
          this.$AES.encode_data(w + "") +
          "&code=" +
          e +
          "&name=" +
          r
      );
    },
    courseScroll(e) {
      if(!this.scrollList.length) {
        return
      }
      // 获取滚动距离
      let num = 0;
      this.scrollList.some((item,index)=>{
        if(index == e) {
          return true;
        }
        num += item;
      })
      // 需要滚动的元素
      let scorllDiv = document.querySelector('.scorll-div')
      scorllDiv.scrollTo({
        top: num,
        behavior: "smooth"
      })
    },
    settraining_Handle(e) {
      this.$set(this,'training_Handle',e);
    },
    // 换一批
    changeNext(e) {
      // 推荐课程
      if(e == 'CourseRecom') {
        if(this.AppCourseRecommendindex == this.AppCourseRecommendList.length - 1) {
          return this.AppCourseRecommendindex = 0;
        }
        return this.AppCourseRecommendindex++;
      }

      // 主编力荐
      if(this.StoresRecommendIndex == this.StoresRecommendList.length - 1) {
        return this.StoresRecommendIndex = 0;
      }
      this.StoresRecommendIndex++;
    },
    // 获取图书分类列表
    getBooksColumns() {
      this.$ajax({
        url: '/hxclass-pc/pc-mall/app/book-category'
      }).then(res=>{
        if(res.code == 200 && res.success) {
          res.data.map(async item=>{
            if(item.name == '精品图书') {
              this.Books_productList = item.children;
            }
            if(item.name == '相关教具') {
              this.training_productList = item.children;
              let arr = await this.getProductListByCode(item.code, 10);
              if(arr.records.length) {
                this.training_Handle = arr.records[0]
                this.$set(this,'training_productList',arr.records)
              }else {
                this.$set(this,'training_productList',[])
              }
            }
          })
          if(this.Books_productList.length) {
            this.Books_productList.map(async item => {
              let arr = await this.getProductListByCode(item.code);
              if(arr.records.length) {
                this.$set(item,'children',arr.records)
              }else {
                this.$set(item,'children',[])
              }
            })
          }
        }
      })
    },
    async getProductListByCode(e,size) {
      let res = await this.$ajax({
        url: '/hxclass-pc/pc-mall/pc/list',
        params: {
          categoryCode: e,
          pageNum: 1,
          pageSize: size || 3
        }
      })
      if(res.code == 200 && res.success) {
        return res.data
      }
    },

    // 跳转子商品分类
    gochildtype(id,childcode,code,name) {
      this.$router.push(
        '/knowledgeMall/educationalCurriculum?id=' +
          this.$AES.encode_data(id + '') +
          '&tabCode=' +
          childcode +
          '&code=' +
          code +
          '&name=' +
          name
      )
    },
    toproDetail(item) {
      if (item.productType == 1 || item.type == 1) {
        this.$router.push({
          path: "/knowledgeMall/courseDetail",
          query: {
            productId: this.$AES.encode_data(item.productId + ""),
            code: item.code ? item.code : item.productCode,
          },
        });
      } else if (
        item.productType == 2 ||
        item.productType == 3 ||
        item.type == 2 ||
        item.type == 3
      ) {
        this.$router.push(
          "/knowledgeMall/curriculumDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      } else if (item.productType == 4 || item.type == 4) {
        this.$router.push(
          "/knowledgeMall/examDetail?productId=" +
            this.$AES.encode_data(item.productId + "")
        );
      }
    },

    // 获取顶部轮播图列表
    getBannerList() {
      this.$ajax({
        url: '/hxclass-pc/Banner/client/list',
        params: {
          locationCode: 'AppMallBanner'
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          if(res.data?.length < 2) {
            this.$set(this.bannerOption,'loop',false)
          }
          this.bannerList = res.data;
        }
      })
    },

    getSwiperIndex() {
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        this.swiperIndex = this.$refs.mySwiperBanner.$swiper.realIndex;
      }, 100);
    },

    onCarouselHand(type,item) {
      let obj = {}
      if(type == 1){
        obj = this.bannerList[this.swiperIndex]
      } else if (type == 2){
        obj = item
      }
      if(obj.jumpType){
        if(obj.jumpType == 1){
          // 外部链接
          window.open(jumpLink);
        } else if (obj.jumpType == 2){
          // 资讯
          this.$router.push('/infoDetail?newsId=' + this.$AES.encode_data(String(obj.jumpLink)) + '&banner=true')
        } else if (obj.jumpType == 3){
          this.$ajax({
            url: '/hxclass-pc/course/getProductIdByCourseId',
            params: {
              courseId: obj.jumpLink
            }
          }).then(res=>{
            if(res.code == 200 && res.success) {
              // 课程
              this.$router.push('/knowledgeMall/courseDetail?productId=' + this.$AES.encode_data(String(res.data)))
            }
          })
        } else if (obj.jumpType == 7){

        }
      }
      // return
      // let link = this.bannerList[this.swiperIndex].link;
      // if (link) {
      //   let reg =
      //     /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      //   if (reg.test(link)) {
      //     // 外部链接
      //     window.open(link);
      //   } else {
      //     // 内部链接
      //     this.$router.push(link);
      //   }
      // }
    },

    // 获取主编力荐列表
    getStoresRecommendList() {
      this.$ajax({
        url: '/hxclass-pc/ProductColumnRelation/client/columnAndProduct',
        params: {
          columnCode: 'AppStoresRecommend'
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.StoresRecommendList = res.data;
        }
      })
    },

    // 获取课程推荐列表
    getAppCourseRecommendList() {
      this.$ajax({
        url: '/hxclass-pc/ProductColumnRelation/client/columnAndProduct',
        params: {
          columnCode: 'AppCourseRecommend'
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          if(res.data.length && res.data[0].productColumnRelations && res.data[0].productColumnRelations.length){
            res.data[0].productColumnRelations.map(item=>{
              item.detail = item.detail.split('##');
            })
            this.AppCourseRecommendList = res.data[0].productColumnRelations;
          }
        }
      })
    },

    // 获取课程推荐广告列表
    getAppCourseRecommendAreaList() {
      this.$ajax({
        url: '/hxclass-pc/Banner/client/list',
        params: {
          locationCode: 'PcMallAdvertiseArea'
        }
      }).then(res=>{
        if(res.code == 200 && res.success) {
          if( res.data && res.data.length ){
            this.AppCourseRecommendAreaList = res.data
          }
        }
      })
    },

    // 获取套餐列表
    getPackageList() {
      this.$ajax({
        url: '/hxclass-pc/pc-packages/pc/recommendation/list'
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.PackageList = res.data;
        }
      })
    },

    // 获取商品分类列表
    getShopTypeList() {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/pc/product-category/new",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          let arr = [];
          res.data.map((item) => {
            if(item.name != "证书课程" && item.name != "职业提升") {
              return false;
            }
            if (item.children && item.children.length) {
              item.children.map((ite, ind) => {
                if (ite.productList && ite.productList.length) {
                } else {
                  item.children.splice(ind, 1);
                }
              });
              arr.push(item);
            } else {
              arr.push(item);
            }
          });
          arr = arr.filter((item) => {
            return (
              (item.children && item.children.length) ||
              (item.productList && item.productList.length)
            );
          });
          this.shoptypeObj.children = []
          arr.map(item=>{
            this.shoptypeObj.children.push(...item.children)
          })

          // 获取元素滚动高度
          setTimeout(()=>{
            let arr = document.getElementsByClassName('scrollBox');
            for(let i=0; i<arr.length; i++) {
              this.scrollList.push(arr[i].scrollHeight)
            }
          },200)
        } else {
          this.$message.error(res.message);
        }
      });
    },
    mouseEnterHandler(){
      this.searchShow = true
      setTimeout(()=>{
        this.searchInputShow = true
        this.$nextTick(()=>{
          if(this.$refs.inputRef){
            this.$refs.inputRef.focus();
          }
        })
      },800)
    },
    blurHandler(){
      this.searchShow = false
      this.searchInputShow = false
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getBannerList(); // 获取顶部轮播
    this.getStoresRecommendList(); // 获取主编力荐列表
    this.getPackageList(); // 获取套餐列表
    this.getAppCourseRecommendList(); // 获取课程推荐列表
    this.getAppCourseRecommendAreaList(); // 获取课程推荐广告列表
    this.getShopTypeList(); // 精品课程列表
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getBooksColumns()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.main-wrap{
  padding-top: 20px;
  .module-inner{
    width: 1200px;
    margin: 0 auto;
  }

  .public-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    .name{
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 36px;
      display: flex;
      align-items: center;
      img{
        width: 20px;
        margin-right: 6px;
      }
    }
    .more{
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 21px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img{
        width: 13px;
        margin-left: 4px;
      }
    }
    .inline-flex{
      display: flex;
      justify-content: space-between;
      width: calc(100% - 204px);
      .search-box{
        display: flex;
        width: 50px;
        transition: 1s;
        justify-content: right;
        /deep/ .ant-btn{
          border: none;
          color: #333333;
          background-color: #F6F6FC;
          box-shadow: none;
          text-shadow: none;
          padding: 0;
        }
        
        /deep/ .ant-input{
          height: 32px;
       
          border: none;
          background-color: #F6F6FC;
        }
        /deep/ .ant-input:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .search-box-open{
        width: 200px;
        border-bottom: 1px solid #000000;
      }
      
    }
  }

  .banner-inner{
    img{
      width: 100%;
      height: 360px;
    }
    .swiper-pagination{
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      /deep/.swiper-pagination-bullet{
        width: 50px;
        height: 3px;
        border-radius: 0;
        background-color: #eeeeee;
      }
    }

    .swiper-slide{
      .banner-img{
        animation: fadeInBiga 0.8s  linear forwards;
        -webkit-animation: fadeInBiga 0.8s  linear forwards;
      }
      @keyframes fadeInBiga{
        0% {
          opacity:1;
        }
        100% {
          opacity: 0.5;
        }
      }
    }
    .swiper-slide-active{
      .banner-img{
        width: 100%;
        animation: fadeInBig 2s  linear forwards;
        -webkit-animation: fadeInBig 2s  linear forwards;
      }
      @keyframes fadeInBig{
        0% {
          opacity: 0.5;
          transform: scale(1.2);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  .main-push{
    margin-top: 30px;
    .product-plate{
      padding: 20px 24px;
      background: #fff;
      overflow: hidden;
      .product-info{
        float: left;
        width: 426px;
        .cover{
          width: 100%;
          height: 145px;
          background-repeat: no-repeat;
          object-fit: cover;
        }
        .txt{
          margin-top: 20px;
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 23px;
          text-indent: 2em;
        }
      }
      .product-list{
        float: right;
        width: calc(100% - 460px);
        display: flex;
        .item{
          cursor: pointer;
          width: calc(100% / 3);
          padding: 0 20px;
          border-right: 1px dashed #C4C4C4;
          .item-side{
            border: 1px solid rgba(0,0,0,0.1);
            .cover{
              width: 100%;
              height: 114px;
              background-repeat: no-repeat;
              object-fit: cover;
            }
            .info{
              padding: 12px;
              .name{
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
                min-height: 48px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .label{
                height: 17px;
                margin-top: 4px;
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
              }
              .price{
                margin-top: 5px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #EC6C01;
                line-height: 24px;
                >span{
                  font-size: 12px;
                }
              }
            }
          }
        }
        .item:nth-child(1){
          padding-left: 0;
        }
        .item:nth-child(3){
          padding-right: 0;
          border-right: none;
        }
      }
    }
  }
  // 套餐
  .package{
    margin-top: 30px;
    .product-package{
      display: flex;
      justify-content: space-between;
      .item-side{
        width: calc((100% - 64px) / 2 );
        display: flex;
        align-items: center;
        .product{
          flex: 1;
          background: #DEDEF6;
          padding: 8px;
          padding-right: 0;
          height: 273px;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 0;
            height: 0;
            border-left: 33px solid transparent;
            border-right: 0 solid transparent;
            border-top: 23px solid #f6f6fc;
          }
          &::before{
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 33px solid transparent;
            border-right: 0 solid transparent;
            border-bottom: 23px solid #f6f6fc;
          }
          .product-side{
            width: calc(100% - 36px);
            display: flex;
            justify-content: space-between;
            position: relative;
            .item{
              width: calc((100% - 16px) / 2 );
              .cover{
                width: 100%;
                height: 160px;
                background-repeat: no-repeat;
                object-fit: cover;
              }
              .info{
                padding: 12px;
                background: #fff;
                .name{
                  font-size: 14px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #333333;
                  line-height: 21px;
                  min-height: 42px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                .price{
                  margin-top: 12px;
                  padding: 0 8px;
                  display: inline-block;
                  background: #F6F6FC;
                  border-radius: 13px 13px 13px 13px;
                  font-size: 13px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 21px;
                }
              }
            }
            .connect-icon{
              position: absolute;
              width: 40px;
              height: 40px;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin:auto;
              z-index: 10;
            }
          }
        }
        .buy-side{
          text-align: center;
          width: 188px;
          height: 265px;
          padding-top: 24px;
          background: #fff;
          border: 1px solid #D0D0F3;
          position: relative;
          .title{
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
            img{
              width: 18px;
              margin-right: 4px;
              margin-top: -3px;
            }
          }
          .price{
            margin-top: 24px;
            font-size: 28px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #EC6C01;
            line-height: 42px;
            >span{
              font-size: 14px;
            }
          }
          .ori-price{
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 21px;
            text-decoration: line-through;
          }
          .btn-foo{
            margin-top: 29px;
            .btn{
              margin: 50px auto 0;
              width: 120px;
              height: 36px;
              line-height: 36px;
              font-size: 14px;
            }
            .all-btn-small{
              border-radius: 0;
            }
            .detai{
              margin-bottom: 10px;
              border: 1px solid #999999;
              color: #333333;
              cursor: pointer;
            }
          }
          .line{
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -50px;
            width: 6px;
            height: 89px;
            background: #D0D0F3;
            border-radius: 20px 0px 0px 20px;
          }
        }
      }
    }
  }

  .course-bg{
    background-image: url(../../assets/image/knowledgeMall/course-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 1px 0 40px;
  }

  // 课程推荐
  .course-inner{
    margin-top: 46px;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
    .course-cover{
      position: absolute;
      bottom: 0px;
      right: 0;
      img{
        width: 816px;
        height: 235px;
        background-repeat: no-repeat;
        object-fit: cover;
        cursor: pointer;
      }
    } 
    .course-right{
      float: left;
      width: calc(100% - 840px);
      .course-list{
        background: #15B7DD;
        margin-top: 16px;
        min-height: 230px;
        .course-list-inner{
          position: relative;
          left: -8px;
          top: -8px;
          background: #ffffff;
          padding: 20px;
          padding-bottom: 8px;
          .course-info:hover{
            transform: translateY(-2px);
            .info>.name{
              color: @theme;
            }
            img{
              transform: scale(1.05);
            }
          }
          .course-info{
            transition: all 0.4s ease;
            cursor: pointer;
            margin-bottom: 8px;
            display: flex;
            img{
              width: 88px;
              height: 88px;
              background-repeat: no-repeat;
              object-fit: cover;
              transition: all 0.4s ease;
            }
            .info{
              margin-left: 22px;
              .name{
                font-size: 15px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 23px;
              }
              .label{
                margin-top: 8px;
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #15B7DD;
                line-height: 15px;
              }
              .price{
                margin-top: 5px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #EC6C01;
                line-height: 24px;
                >span{
                  font-size: 12px;
                }
              }
            }
          }
          .tips{
            margin-bottom: 12px;
            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 15px;
          }
          .list{
            border-top: 1px dashed #C4C4C4;
            padding-top: 12px;
            .item{
              margin-bottom: 9px;
               white-space: nowrap;   //不换行
                overflow: hidden;       //超出部分隐藏
                text-overflow: ellipsis;    //文本溢出显示省略号
              img{
                width: 18px;
                margin-right: 8px;
              }
            }
            .item:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  // 精品课程
  .good-inner{
    margin-top: 38px;
    padding-bottom: 30px;
    .course-menu-list{
      display: flex;
      // align-items: center;
      .course-menu{
        width: 174px;
        margin-right: 30px;
        .swiper{
            max-height: 350px;
            .swiper-item:hover{
              .name{
                color: @theme;
              }
            }
            .swiper-item{
              cursor: pointer;
            width: 100%;
            height: 48px;
            background: #FFFFFF;
            border-radius: 2px;
            text-align: center;
            display: flex;
            align-items: center;
            padding-left: 44px;
            .name{
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 24px;
              margin-right: 10px;
              padding-left: 4px;
              position: relative;
              &::before{
                content: '';
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-left: 4px solid #3FC0DF;
                position: absolute;
                left: -3px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
        .navigation{
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          .option{
            background: #ffffff;
            width: calc((100% - 10px) / 2);
            border-radius: 6px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
              width: 18px;
            }
            .up-active, .down-active{
              display: none;
            }
            &:hover{
              box-shadow: 4px 4px 10px 0px rgba(0,0,0,0.08);
            }
          }
          .voice-prev{
            .up-active{
              display: none;
            }
          }
          .voice-prev:hover{
            .up{
              display: none;
            }
            .up-active{
              display: block;
            }
          }
          .voice-next:hover{
            .down{
              display: none;
            }
            .down-active{
              display: block;
            }
          }
        }
      }
      .course-list{
        flex: 1;
        background: #ffffff;
        padding: 24px 15px 24px 30px;
        .scorll-div{
          max-height: 450px;
          overflow-y: scroll;
          padding-right: 15px;
        }
        .item{
          .big-name{
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            >span{
              font-size: 14px;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 18px;
            }
            .line{
              display: inline-block;
              margin-left: 10px;
              flex: 1;
              height: 1px;
              background: rgba(0,0,0,0.1);
            }
          }
          .product-list{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &:after { 
              content: ""; 
              width: calc((100% - 40px) / 3);
            }
            .item-side{
              width: calc((100% - 40px) / 3);
              margin-bottom: 20px;
              display: flex;
              cursor: pointer;
              transition: all 0.4s ease;
              img{
                width: 140px;
                height: 80px;
                background-repeat: no-repeat;
                object-fit: cover;
                transition: all 0.4s ease;
              }
              .info{
                flex: 1;
                margin-left: 14px;
                .name{
                  font-size: 14px;
                  font-family: PingFang SC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #666666;
                  line-height: 18px;
                  margin-bottom: 0;
                }
                .label{
                  margin-top: 5px;
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #999999;
                  line-height: 15px;
                  white-space: nowrap;
                  transform: scale(0.9);
                  transform-origin: left;
                }
                .price{
                  font-size: 14px;
                  margin-top: 5px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #EC6C01;
                  line-height: 15px;
                  >span{
                    font-size: 12px;
                  }
                }
              }
            }
            .item-side:hover{
              transform: translateY(-2px);
              .name{
                color: @theme;
              }
              img{
                transform: scale(1.05);
              }
            }
          }
        }
        /* 设置滚动条的样式 */
        .scorll-div::-webkit-scrollbar {
          width:5px;
          position: relative;
        }
        /* 滚动槽 */
        .scorll-div::-webkit-scrollbar-track {
          -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
          border-radius:0;
          background:rgba(255, 255, 255, 0.1);
        }
        /* 滚动条滑块 */
        .scorll-div::-webkit-scrollbar-thumb {
          border-radius:10px;
          background:#ECF5F6;
          -webkit-box-shadow:inset006pxrgba(0,0,0,0.2);
        }
      }
    }
  }

  .book-bg{
    background-image: url(../../assets/image/knowledgeMall/book-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 50px;
  }
  
  // 图书教具
  .book-inner{
    display: flex;
    padding-top: 22px;
    .book-cover{
      width: 126px;
      margin-right: 30px;
    }
  }
  .huaxia-book{
    .book-cover{
      height: 363px;
    }
    .box_Back{
      flex: 1;
      .bookBanner{
        width: calc(1200px - 156px);
        margin: 0 auto;
        position: relative;
        .swiper-wrapper{
          .swiper-slide{
            width: 330px;
          }
        }
        .contentBox{
          border-top: 4px solid #ffffff;
          padding: 15px 20px;
          background-image: url(~@/assets/image/RevisionHome/back1.png);
          height: 440px;
          .pro_box:hover{
            box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
            transform: translateY(-2px);
            .name{
              color: @theme;
            }
            img{
              transform: scale(1.05);
            }
          }
          .pro_box{
            display: flex;
            padding: 20px;
            background-color: #fff;
            margin-top: 20px;
            cursor: pointer;
            transition: all 0.4s ease;
            .banner-img{
              width: 80px;
              height: 80px;
              transition: all 0.4s ease;
            }
            .r_mess{
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 24px;
              padding: 4px 0;
              .name{
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 21px;
              }
              .priceOption {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .price{
                  font-size: 16px;
                  font-family: DIN Alternate-Bold, DIN Alternate;
                  font-weight: bold;
                  color: #EC6C01;
                  line-height: 20px;
                  position: relative;
                  margin-left: 8px;
                }
                .price::before{
                  content: '￥';
                  font-size: 12px;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #EC6C01;
                  position: absolute;
                  left: -10px;
                  top: 1px;
                }
                .joinCar{
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                }
              }
            }
          }
          .pro_box:first-child{
            margin-top: 0;
          }
        }
        .typeBox{
          display: flex;
          align-items: center;
          padding: 15px 20px;
          background-color: #FFF7EC;
          .banner-img{
            width: 19px;
            height: 19px;
            margin-right: 11px;
          }
          .typeText{
            flex: 1;
          }
          .btn{
            cursor: pointer;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #A8A8A8;
            line-height: 21px;
            .aIcon{
              margin-left: 4px;
            }
          }
        }
        .cccBox{
          background-image: url(~@/assets/image/RevisionHome/back2.png);
        }
        .typecolor{
          color: #FBB454 !important;
        }
        .Books_swiper-button-next,.Books_swiper-button-prev{
          width: 40px;
          height: 40px;
          color: rgba(0,0,0,0.1);
          background-color: #ffffff;
          border: 1px solid rgba(0,0,0,0.1);
          text-align: center;
          line-height: 40px;
          border-radius: 50%;
          cursor: pointer;
        }
        .Books_swiper-button-next:hover,.Books_swiper-button-prev:hover{
          color: #ffffff;
          background-color: rgba(0,0,0,0.1);
        }
        .Books_swiper-button-next{
          position: absolute;
          left: -50px;
          top: 50%;
        }
        .Books_swiper-button-prev{
          position: absolute;
          right: -50px;
          top: 50%;
        }
      }

      .title{
        margin-top: 86px;
        img{
          width: 22px;
        }
      }
    }
  }
  .jingpin-book{
    .book-cover{
      height: 291px;
    }
    .handle{
      flex: 1;
      margin: 0 auto;
      display: flex;
      padding-top: 18px;
      .picture{
        width: 220px;
        height: 275px;
        border-radius: 5px;
        box-shadow: 20px 20px 20px 0px rgba(0,0,0,0.1);
        background-color: #fff;
        .banner-img{
          width: 100%;
          height: 220px;
        }
        .colorBox{
          width: 100%;
          height: 31px;
          background: linear-gradient(135deg, #FFF3E4 0%, #FFDDB1 100%);
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            height: 13px;
          }
        }
      }
      .handleMess{
        flex: 1;
        .trainingBanner{
          width: 712px;
          height: 196px;
          margin: 0 auto;
          margin-top: 23px;
          position: relative;
          .swiper-slide:hover{
            box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.1);
            transform: translateY(-2px);
            .name{
              color: @theme;
            }
            img{
              transform: scale(1.05);
            }
          }
          .swiper-slide{
            transition: all 0.4s ease;
            background-color: #fff;
            padding: 10px;
            cursor: pointer;
            .banner-img{
              width: 100px;
              height: 100px;
              transition: all 0.4s ease;
            }
            .name{
              height: 36px;
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 18px;
              margin-top: 4px;
            }
            .priceOption {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              .price{
                font-size: 16px;
                font-family: DIN Alternate-Bold, DIN Alternate;
                font-weight: bold;
                color: #EC6C01;
                line-height: 20px;
                position: relative;
                margin-left: 8px;
              }
              .price::before{
                content: '￥';
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #EC6C01;
                position: absolute;
                left: -10px;
                top: 1px;
              }
              .joinCar{
                width: 16px;
                height: 16px;
                cursor: pointer;
              }
            }
          }
          .training_swiper-button-next,.training_swiper-button-prev{
            width: 40px;
            height: 40px;
            color: rgba(0,0,0,0.1);
            background-color: #ffffff;
            border: 1px solid rgba(0,0,0,0.1);
            text-align: center;
            line-height: 40px;
            border-radius: 50%;
            cursor: pointer;
          }
          .training_swiper-button-next:hover,.training_swiper-button-prev:hover{
            color: #ffffff;
            background-color: rgba(0,0,0,0.1);
          }
          .training_swiper-button-next{
            position: absolute;
            left: -49px;
            top: 39%;
          }
          .training_swiper-button-prev{
            position: absolute;
            right: -49px;
            top: 39%;
          }
        }
        .price{
          font-size: 28px;
          font-family: DIN Alternate-Bold, DIN Alternate;
          font-weight: bold;
          color: #EC6C01;
          line-height: 42px;
          position: relative;
          margin-left: 48px;
          margin-top: 10px;
        }
        .price::before{
          content: '￥';
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #EC6C01;
          position: absolute;
          left: -11px;
          top: 3px;
        }
        .mess{
          display: flex;
          align-items: center;
          padding: 17px 0 0 35px;
          .btn1,.btn2{
            width: 100px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
            margin-left: 24px;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
          }
          .btn1{
            background-color: #15B7DD;
            border-radius: 0;
          }
          .btn2{
            color: #15B7DD;
            border: 1px solid #15B7DD;
            .aIcon{
              width: 8px;
            }
          }
          .name{
            flex: 1;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 26px;
          }
        }
      }
    }
  }
}

</style>
